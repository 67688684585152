export const BASE_URL = "https://datashift-test.azurewebsites.net";
export const BASE_FE_URL = "https://app.datashift.cc";
export const ENVIRONMENT = 'test';

export const GOOGLE_CLIENT_ID = '676286134561-mfavpdk56v24ibobujf93oqpap10h0i2.apps.googleusercontent.com';
export const OAUTH2ENDPOINT = 'https://accounts.google.com/o/oauth2/v2/auth';

export const FACEBOOK_APP_ID = '367459376408850';
export const FACEBOOK_CLIENT_ID = '568803209127919';
export const FACEBOOK_APP_VERSION = 'v20.0';

