import {
    FC,
    useState,
    useCallback,
} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
} from '@mui/material';

import { IPopUpDialogue } from 'Components/Dialogs/type';
import useLocalized from 'CustomHooks/useLocalized/useLocalized';
import { Close } from '@mui/icons-material';
import { cancel } from 'Constants/TranslationConstants';
  
const PopUpDialog: FC<IPopUpDialogue> = ({
  dialogueProps: {
    popUpDialogProps,
  },
}) => {
  const [open, setOpen] = useState(true);
  const disableOutsideClickClose = popUpDialogProps?.action?.callToAction === undefined;
  const [inputValue, setInputValue] = useState('');
  const cancel_text = useLocalized(cancel);
  const handleClose = useCallback(() => {
    if (disableOutsideClickClose || popUpDialogProps?.closeEnabled) {
      setOpen(false);
    }
  }, [disableOutsideClickClose, popUpDialogProps?.closeEnabled]);

  return (
    <Dialog open={open}>
      <div className="sync-dialog-head-button">
        <IconButton onClick={() => handleClose()} >
          <Close />
        </IconButton>
      </div>
      <div className='dialog-body'>
        {popUpDialogProps?.image && (
          <div className="pop-up-dialog-image">
            <img src={popUpDialogProps.image} alt='pop-up-dialog-img'/>
          </div>
        )}
        {popUpDialogProps?.title && (
          <DialogTitle className="pop-up-dialog-title"
            dangerouslySetInnerHTML={{__html: popUpDialogProps.title}}>
          </DialogTitle>
        )}
        {popUpDialogProps?.description && (
          <DialogContent className="pop-up-dialog-content"
            dangerouslySetInnerHTML={{__html: popUpDialogProps.description}}>
          </DialogContent>
        )}
        {popUpDialogProps?.element && (
          <DialogContent className="pop-up-dialog-content">
              {popUpDialogProps?.element}
          </DialogContent>
        )}
        
        {popUpDialogProps?.inputName && (
        <div className='pop-up-button-input'>
          <TextField
            value={inputValue}
            onChange={(e) => {setInputValue(e.target.value)}}
            size='medium'
            label= {popUpDialogProps?.inputName}
            className='pop-up-button-input-text-field'
            fullWidth
            autoComplete="off"
            disabled={false}
          />
        </div>
        )}
        {popUpDialogProps?.secondDescription && (
          <DialogContent className="pop-up-dialog-content"
            dangerouslySetInnerHTML={{__html: popUpDialogProps.secondDescription}}>
          </DialogContent>
        )}
        <div className="pop-up-button">
          <DialogActions>
            {popUpDialogProps?.action && (
              <Button
                key={popUpDialogProps?.action.name}
                onClick={() => {
                  handleClose();
                  popUpDialogProps.action!.callToAction?.();
                }}
                variant="contained"
                color="primary"
              >
                {popUpDialogProps?.action.name}
              </Button>  
            )}
            {(popUpDialogProps?.sendAction) && (
              <Button
                key={popUpDialogProps?.sendAction.name}
                onClick={() => {
                  handleClose();
                  popUpDialogProps.sendAction!.callToAction?.(inputValue)
                }}
                variant="contained"
                color="primary"
              >
                {popUpDialogProps?.sendAction.name}
              </Button>  
            )}
            {(popUpDialogProps?.showCloseButon) && (
            <Button 
            onClick={handleClose}
            variant="contained"
            color='inherit'
            >
              {cancel_text}
            </Button> 
          )}
          </DialogActions>
        </div>
      </div>
    </Dialog>
  );
};

export default PopUpDialog;
  