import { IFunnelReportState, IFunnelReportFilter } from 'Modules/Reports/Redux/Reducers/FunnelAnalysisReducer/types';
import {
  LOADING,
  SUCCESS,
  FAIL,
  DEFAULT,
} from 'Redux/types';
import {
  FunnelReportListActionType,
  FUNNEL_REPORT_LIST_LOADING,
  FUNNEL_REPORT_LIST_SUCCESS,
  FUNNEL_REPORT_LIST_FAIL,
  SET_FUNNEL_REPORT_LIST_FILTER,
  SET_FUNNEL_REPORT_LIST_COMPLEX_FILTER,
  RESET_FUNNEL_REPORT_LIST_COMPLEX_FILTER,
  SET_FUNNEL_REPORT_LIST_SORTING_CRITERIA,
  FUNNEL_REPORT_HIDDENSTATE_LOADING,
  FUNNEL_REPORT_HIDDENSTATE_SUCCESS,
  FUNNEL_REPORT_HIDDENSTATE_FAIL,
} from 'Modules/Reports/Redux/types';
import SortingService from 'Services/SortingService/SortingService';
import { ComplexFilterSet } from 'CustomHooks/useComplexFilter/types';
import { SortingCriteria } from 'CustomHooks/useSort/types';

const initialState: IFunnelReportState = {
  status: DEFAULT,
  data: [],
  siteReports: {
    impressions: {
      value: 0,
      comparison: null,
    },
    interactions: {
      value: 0,
      comparison: null,
    },
    interactionRate: {
      value: 0,
      comparison: null,
    },
    clicks: {
      value: 0,
      comparison: null,
    },
    videoViews: {
      value: 0,
      comparison: null,
    },
    sessions: {
      value: 0,
      comparison: null,
    },
    sessionRate: {
      value: 0,
      comparison: null,
    },
    engagedSessions: {
      value: 0,
      comparison: null,
    },
    engagedSessionsRate: {
      value: 0,
      comparison: null,
    },
    interactionCost: {
      value: 0,
      comparison: null,
    },
    conversionCost: {
      value: 0,
      comparison: null,
    },
    costShare: {
      value: 0,
      comparison: null,
    },
    conversionRegistrations: {
      value: 0,
      comparison: null,
    },
    conversionRegistrationsRate: {
      value: 0,
      comparison: null,
    },
    conversionSales: {
      value: 0,
      comparison: null,
    },
    conversionSalesRate: {
      value: 0,
      comparison: null,
    },
    interactionWithProductCategories: {
      value: 0,
      comparison: null,
    },
    interactionWithProductCategoriesRate: {
      value: 0,
      comparison: null,
    },
    interactionWithProductPages:{
      value: 0,
      comparison: null,
    },
    interactionWithProductPagesRate:{
      value: 0,
      comparison: null,
    },
    interestOfBuyingProducts: {
      value: 0,
      comparison: null,
    },
    interestOfBuyingProductsRate: {
      value: 0,
      comparison: null,
    },
    intentionOfBuyingProducts:{
      value: 0,
      comparison: null,
    },
    intentionOfBuyingProductsRate:{
      value: 0,
      comparison: null,
    }
  },
  sortingCriteria: {
    key: 'channel.displayName',
    order: 'ASCENDING',
    dataType: 'STRING',
  },
  filter: {
    predefinedFilterId: {
      value: '',
      appliedOn: ['predefinedFilterId'],
      matchingType: 'INCLUDES',
      isUsingIdToFetch: false,
    },
    dataAtDate1: {
      value: null,
      appliedOn: ['dataAtDate1'],
      matchingType: 'INCLUDES',
      isUsingIdToFetch: false,
    },
    dataAtDate2: {
      value: null,
      appliedOn: ['dataAtDate2'],
      matchingType: 'INCLUDES',
      isUsingIdToFetch: false,
    },
    dataAtDate3: {
      value: null,
      appliedOn: ['dataAtDate3'],
      matchingType: 'INCLUDES',
      isUsingIdToFetch: false,
    },
    dataAtDate4: {
      value: null,
      appliedOn: ['dataAtDate4'],
      matchingType: 'INCLUDES',
      isUsingIdToFetch: false,
    },
  },
  complexFilter: {
    name: '',
    filterSet: [],
  },
  funnelAnalysisTableViewState: {
    userSettings: {}
  },
};

const funnelAnalysisReducer = (state = initialState, action: FunnelReportListActionType): IFunnelReportState => {
  const { type, payload } = action;
  switch (type) {
    case SET_FUNNEL_REPORT_LIST_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...payload as IFunnelReportFilter,
        },
        selectedAll: false,
      };
    case SET_FUNNEL_REPORT_LIST_COMPLEX_FILTER:
      return {
        ...state,
        complexFilter: payload as ComplexFilterSet,
      };
    case RESET_FUNNEL_REPORT_LIST_COMPLEX_FILTER:
      return {
        ...state,
        complexFilter: {
          name: '',
          filterSet: [],
        },
      }
    case SET_FUNNEL_REPORT_LIST_SORTING_CRITERIA:
      return {
        ...state,
        sortingCriteria: payload as SortingCriteria,
      };
    case FUNNEL_REPORT_LIST_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case FUNNEL_REPORT_LIST_SUCCESS:
      const sortedData = SortingService.getSortedArray((payload as Partial<IFunnelReportState>).data!, state.sortingCriteria);
      return {
        ...state,
        data: sortedData,
        siteReports: (payload as Partial<IFunnelReportState>).siteReports!,
        status: SUCCESS,
      };
    case FUNNEL_REPORT_LIST_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    case FUNNEL_REPORT_HIDDENSTATE_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case FUNNEL_REPORT_HIDDENSTATE_SUCCESS:
      return {
        ...state,
        funnelAnalysisTableViewState: (payload as Partial<IFunnelReportState>).funnelAnalysisTableViewState!,
        status: SUCCESS,
      };
    case FUNNEL_REPORT_HIDDENSTATE_FAIL:
    return {
      ...state,
      status: FAIL,
    };
    default:
      return state;
  }
};

export default funnelAnalysisReducer;
